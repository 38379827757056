import { useStaticQuery, graphql } from "gatsby"

export const useMetaIntro = () => {
  return useStaticQuery(graphql`
    query MetaIntro {
      intro: markdownRemark(
        fields: { contentKind: { eq: "meta" }, slug: { eq: "intro" } }
      ) {
        html
      }
    }
  `).intro.html
}

import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card, { HeadCard } from "../components/card"
import EncloseFloats from "../components/enclose-floats"
import UnlinkedBlock from "../components/unlinked-block"
import { px } from "../styles/units"
import breakpoints from "../styles/breakpoints"
import v from "../styles/vars"
import { useMetaIntro } from "../meta/intro"
import { useMetaCollectionOnline } from "../meta/collection/online"
import { useMetaCollectionGroups } from "../meta/collection/groups"
import { useMetaCollectionConventions } from "../meta/collection/conventions"
import { useMetaCollectionStores } from "../meta/collection/stores"
import { useMetaCollectionJapaneseEvents } from "../meta/collection/japanese-events"

const Grid = styled.div`
  display: grid;

  grid-template-columns: auto;
  grid-gap: ${px(v.gap)};

  ${breakpoints.downHorizontalNavigation} {
    grid-gap: ${px(v.mobileGap)};
  }

  ${breakpoints.upBreakpointGridColumns} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const IndexPage = () => {
  const intro = useMetaIntro()
  const online = useMetaCollectionOnline().html
  const groups = useMetaCollectionGroups().html
  const conventions = useMetaCollectionConventions().html
  const stores = useMetaCollectionStores().html
  const japaneseEvents = useMetaCollectionJapaneseEvents().html

  return (
    <Layout variant="home">
      <SEO title="Home" />
      <main>
        <EncloseFloats>
          <HeadCard>
            <div dangerouslySetInnerHTML={{ __html: intro }} />
          </HeadCard>

          <UnlinkedBlock to="/online/">
            <HeadCard
              title="Online Communities"
              buttonText="Find Online Communities →"
            >
              <div dangerouslySetInnerHTML={{ __html: online }} />
            </HeadCard>
          </UnlinkedBlock>

          <Grid>
            <UnlinkedBlock to="/groups/">
              <Card title="Groups" buttonText="Find Groups →">
                <div dangerouslySetInnerHTML={{ __html: groups }} />
              </Card>
            </UnlinkedBlock>
            <UnlinkedBlock to="/conventions/">
              <Card title="Conventions" buttonText="Find Conventions →">
                <div dangerouslySetInnerHTML={{ __html: conventions }} />
              </Card>
            </UnlinkedBlock>
            <UnlinkedBlock to="/stores/">
              <Card title="Stores" buttonText="Find Stores →">
                <div dangerouslySetInnerHTML={{ __html: stores }} />
              </Card>
            </UnlinkedBlock>
            <UnlinkedBlock to="/japanese-events/">
              <Card title="Japanese events" buttonText="Find Japanese events →">
                <div dangerouslySetInnerHTML={{ __html: japaneseEvents }} />
              </Card>
            </UnlinkedBlock>
          </Grid>
        </EncloseFloats>
      </main>
    </Layout>
  )
}

export default IndexPage
